<template>
    <div class="base-width">
        <div class="search">
            <index-search :read-only-value="readOnly"></index-search>
        </div>
        <div class="body">
            <el-row :gutter="15">
                <el-col :span="17" :offset="1">
                    <div class="question-body">
                        <div v-if="isLoading===false&&answer.data.length===0" class="search-loading">
                            <p>抱歉，你搜索的问题暂无答案。请点击[我要提问]</p>
                            <p>将问题纳入BIGEMAP知识库,耐心等待答主解答</p>
                            <button @click="addQuestion" class="btn">
                                我要提问
                            </button>
                        </div>
                        <question :need-username="false" v-for="q in answer.data" :key="q.answer_id"
                                  :content="q"></question>
                    </div>
                    <div class="page" v-if="answer.total>10">
                        <el-pagination
                            @current-change="pageChange"
                            background
                            :current-page.sync="searchData.pageNumber"
                            layout="prev, pager, next"
                            :total="answer.total">
                        </el-pagination>
                    </div>
                </el-col>
                <el-col :span="5">
                    <div class="hot-body box-shadow"></div>
                    <today-hot></today-hot>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import IndexSearch from "@/components/index/top/index-search.vue";
import TodayHot from "@/components/wenda/right/today-hot.vue";
import Question from "@/components/wenda/left/question.vue";

export default {
    components: {
        IndexSearch, Question, TodayHot
    },
    data() {
        return {
            answer: {
                data: [],
                total: 0
            },
            readOnly: '',
            isLoading: false,
            baseUrl: '/bbs/search/search',
            searchData: {
                pageNumber: 1,
            },
        }
    },
    mounted() {
        if (this.$route.query.title) {
            this.readOnly = this.$route.query.title;
            this.search();
        }
    },
    watch: {
        '$route.query.title': function () {
            this.search();
            console.log('do search', this.$route.query.title);
            // console.log(this.$route.query.title);
        }
    },
    methods: {
        addQuestion() {
            if (!this.$tools.checkLogin()) {
                return;
            }
            this.$router.push({
                path: '/add',
                query: {
                    title: this.$route.query.title || "",
                }
            });
            // this.$store.commit('updateCurrentQuestion',{data:{title:this.$route.query.title},title:'添加问答',show:Math.random()});
        },
        pageChange() {
            this.search();
        },
        search() {
            this.isLoading = true;
            this.$bbs.get(this.baseUrl, {
                params: {
                    pageNumber: this.searchData.pageNumber,
                    search: {
                        text: this.$route.query.title,
                    }
                },
                need:{
                    progress:true,
                }
            }).then((data) => {
                this.isLoading = false;
                this.answer.data = data.data.data;
                this.answer.total = data.data.total;
            }).catch(() => {
                this.isLoading = false;
            });
        }
    }
}
</script>
<style scoped lang="less">
.search {
    //background-color: #fff;
    height: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 10px;
}

.body {
    padding: 0 10px;

    .question-body {
        min-height: 365px;
        //margin-top: 15px;
        width: 100%;
        background-color: #fff;

        .search-loading {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #333;
            min-height: 365px;
            font-size: 14px;

            p {
                margin-bottom: 10px;
            }

            .btn {
                color: #fff;
                background-color: #307DDB;
                padding: 10px 25px;
                border-radius: 50px;
            }
        }
    }

    .page {
        margin-top: 10px;
        padding: 10px 0;
        background: #fff;
    }

    .hot-body {
        background-color: #fff;
    }
}

</style>
